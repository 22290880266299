import React, { ReactElement } from "react";

import NavBar from "../../components/NavBar";
import { CustomizedDialogs } from "../../components/Footer/Modal";
import iconError from "../../assets/im-illustration-error.svg";

import {
  ContentContainer,
  Footer,
  PageContainer,
  PageDescription,
  PageTitle,
  Image,
} from ".";

function PasswordRecoverySuccessPage(): ReactElement {
  return (
    <>
      <NavBar onlyLogo />
      <PageContainer>
        <div className="container">
          <ContentContainer>
            <PageTitle>Passwort Zurücksetzung erneut versuchen</PageTitle>
            <PageDescription>
              Das hat leider zu lange gedauert. Die Verifizierung ist
              abgelaufen. Bitte starte den Prozess noch mal von vorn.
            </PageDescription>
            <Image src={iconError} />
          </ContentContainer>
          <Footer>
            <CustomizedDialogs />
          </Footer>
        </div>
      </PageContainer>
    </>
  );
}

export default PasswordRecoverySuccessPage;
